import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../../Page";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import * as Icon from "../../Components/Icon";
import * as Widget from "../../Components/Widget";
import TagItem from "../../Components/TagItem";

class ProfilePage extends Component {
  state = {
    tags: []
  };

  componentDidMount() {
    let { appActions } = this.props;
    appActions
      .fetchMyTags()
      .then(tags => this.setState({ tags }))
      .catch(err => console.warn(err));
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.profile && this.props.profile) {
      let { appActions } = this.props;
      appActions
        .fetchMyTags()
        .then(tags => this.setState({ tags }))
        .catch(err => console.warn(err));
    }
  }

  render() {
    let { navActions, profile } = this.props;
    let { tags } = this.state;

    return (
      <Wrapper>
        <div
          className="button"
          onClick={() => {
            if (profile) {
              return this._logout();
            }
            return navActions.push("/social-login");
          }}
        >
          <img
            src="/images/nfc-logo.png"
            style={{ marginRight: 10, width: 25 }}
          />
          <span>{profile ? "登出" : "登入"}</span>
        </div>

        <div className="container">
          {profile ? (
            <>
              <Widget.Col
                justify="center"
                align="center"
                extraCss="margin-bottom: 50px; max-width: 300px; width: 100%;"
              >
                <h1>{"TAG\nLIST"}</h1>
                <Widget.Row extraCss="position: relative; max-width: 250px; width: 100%;">
                  <Widget.Input extraCss="width: 100%; height: 35px; padding: 0px 35px 0 15px;" />
                  <div style={{ position: "absolute", right: 0 }}>
                    <Icon.Search
                      color="#fff"
                      style={{
                        width: 35,
                        height: 35,
                        background: "#72A2DD",
                        borderRadius: "50%"
                      }}
                    />
                  </div>
                </Widget.Row>
              </Widget.Col>
              <Widget.Row
                wrap="true"
                justify="flex-start"
                extraCss={`
                    max-width: 520px;
                    width: 100%;
                    @media screen and (max-width: 414px) {
                      max-width: 300px;
                    }
                `}
              >
                {tags.map((tag, i) => (
                  <TagItem key={tag.id} tag={tag} isYellow={i % 2 === 1} />
                ))}
              </Widget.Row>
            </>
          ) : (
            <div>請先登入！</div>
          )}
        </div>
      </Wrapper>
    );
  }

  _logout = () => {
    let { appActions, navActions } = this.props;
    appActions.logout().then(() => navActions.push("/"));
  };
}

const Wrapper = styled.div`
  & > .button {
    padding: 20px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    color: #224e86;
  }

  & > .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 100vw;
    height: 100vh;

    & h1 {
      margin-bottom: 40px;
      border-bottom: 5px solid #e6e54f;
      max-width: 300px;
      width: 100%;
      text-align: right;
      white-space: pre-wrap;
      letter-spacing: 4px;
      font-size: 60px;
      color: #224e86;
    }
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state)
    }),
    ActionCreator
  )(ProfilePage)
);
