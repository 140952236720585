import React, { Component } from "react";
import styled from "styled-components";
import Link from "./Link";

class TagItem extends Component {
  render() {
    const { isYellow, tag, isOnlyRead } = this.props;

    if (isOnlyRead) {
      return (
        <Wrapper isYellow={isYellow} css="margin: 0;" rwdCss="margin: 0;">
          <div>
            <span>{tag.name}</span>
          </div>
        </Wrapper>
      );
    }

    return (
      <Link to={`/edit-tag?uid=${tag.uid}`}>
        <Wrapper isYellow={isYellow}>
          <div>
            <span>{tag.name}</span>
          </div>
        </Wrapper>
      </Link>
    );
  }
}

const Wrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${props => (props.isYellow ? "#D4D33B " : "#4A8DDF")};
  position: relative;
  margin: 15px;
  ${props => props.css || ""};
  @media screen and (max-width: 500px) {
    width: 80px;
    height: 80px;
    margin: 10px;
    ${props => props.rwdCss || ""};
  }

  & > div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: ${props => (props.isYellow ? "#E6E54F  " : "#72A2DD")};
    position: absolute;
    bottom: 5px;
    left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 479px) {
      width: 80px;
      height: 80px;
    }

    & > span {
      font-size: 16px;
      color: #fff;
      margin-bottom: 38px;
      margin-left: 12px;
      @media screen and (max-width: 479px) {
        font-size: 14px;
        margin-bottom: 30px;
        margin-left: 10px;
      }
    }
  }
`;

export default TagItem;
