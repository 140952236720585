import React from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";
import Selectors from "../../Selectors";

const LinkWithLangPrefix = props => {
  let { className, extraStyle, children, to } = props;

  return (
    <Link className={className} style={extraStyle} to={`/${to}`}>
      {children}
    </Link>
  );
};

export default connect(
  (state, ownProps) => ({}),
  null
)(LinkWithLangPrefix);
